import { gql, useApolloClient, useQuery } from '@apollo/client';
import { User, useAuth0 } from '@auth0/auth0-react';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/browser';
import { QueryUsersPayload, TeamMembershipRole } from 'core/metadata-graphql';
import { useNotification } from 'reablocks';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { IntercomProps, useIntercom } from 'react-use-intercom';
import { AuthProvider } from './AuthContext';
import { getString } from 'core/utils/saferGet';

const GET_USER = gql`
  query usersByEmail($email: [String!]!) {
    usersByEmail(email: $email) {
      errors
      records {
        auth0UserId
        createdAt
        email
        firstName
        fullName
        id
        lastLoginTime
        lastName
        metadata
        teams {
          id
          name
          role
          organizationName
        }
        tenantId
        userType
        username
      }
    }
  }
`;

class QueryUser extends User {
  ['https://metadata.api/userAndTeam']?: {
    userId: `${number}`;
    tenantId: `${number}`;
    teamId: `${number}`;
    name: string;
    email: string;
    role: `${TeamMembershipRole}`;
    teamName: string;
  };
}

export const Auth: FC<PropsWithChildren> = ({ children }) => {
  const client = useApolloClient();
  const {
    logout: auth0Logout,
    loginWithRedirect,
    user: auth0User,
    isLoading: auth0IsLoading,
    error,
  } = useAuth0<QueryUser>();
  const { boot } = useIntercom();
  const { notifySuccess, notifyError } = useNotification();

  const canResetPassword = auth0User?.sub?.startsWith('auth0|');

  const {
    loading: userLoading,
    data: users,
    refetch: refetchUser,
  } = useQuery<{ usersByEmail: QueryUsersPayload }>(GET_USER, {
    variables: { email: [auth0User?.email] },
    skip: !auth0User?.email,
  });

  const [user] = users?.usersByEmail?.records || [];
  const roleFromApi = user?.teams[0]?.role;
  // If the user has a role on the id token, we should only consider them an admin if the API and
  // the token are in agreement. Prior to this change, newly-assigned admins would fail to make
  // calls because their existing token would not have the new role.
  const roleOnIdToken = auth0User?.['https://metadata.api/userAndTeam']?.role;
  const hasAdminRole = roleOnIdToken
    ? roleFromApi === TeamMembershipRole.Admin && roleOnIdToken === TeamMembershipRole.Admin
    : roleFromApi === TeamMembershipRole.Admin;

  useEffect(() => {
    async function setupUser() {
      if (import.meta.env.PROD) {
        FullStory.identify(user.email, {
          displayName: user.fullName,
          email: user.email,
          company: getString(user, 'metadata.company'),
          title: getString(user, 'metadata.title'),
          createdAt: user.createdAt,
        });

        Sentry.configureScope(scope =>
          scope.setUser({
            username: user.email,
            name: user.fullName,
            email: user.email,
          })
        );

        boot({
          hideDefaultLauncher: true,
          company: {
            companyId: getString(user, 'metadata.company'),
            name: getString(user, 'metadata.company'),
          },
          title: getString(user, 'metadata.title'),
          email: user.email,
          name: user.fullName,
          createdAt: `${new Date(user.createdAt).getTime()}`,
        } as Partial<IntercomProps>);
      }
    }

    // If we got the auth0 user and we are not already loading the user
    if (user) {
      setupUser();
    }
  }, [user]);

  const logout = useCallback(async () => {
    client.clearStore();
    auth0Logout({
      logoutParams: {
        returnTo: `${window.location.origin}/login`,
      },
    });
  }, [client, auth0Logout]);

  // const mutation = useMutation(resetPasswordAuth0Mutation);
  const resetPassword = useCallback(
    async (email: string) => {
      try {
        // await mutation.mutateAsync(email);
        notifySuccess(`Password reset instructions were sent to ${email}`);
      } catch {
        notifyError('Something unexpected happened trying to reset your password');
      }
    },
    [notifyError, notifySuccess]
  );

  const values = useMemo(
    () => ({
      user,
      hasAdminRole,
      login: loginWithRedirect,
      canResetPassword,
      isLoading: auth0IsLoading || userLoading,
      logout,
      resetPassword,
      refetchUser,
      error,
    }),
    [
      user,
      hasAdminRole,
      loginWithRedirect,
      canResetPassword,
      userLoading,
      auth0IsLoading,
      logout,
      resetPassword,
      refetchUser,
      error,
    ]
  );

  return <AuthProvider value={values}>{children}</AuthProvider>;
};
