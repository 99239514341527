import { createContext } from 'react';
import { FlagContextValue } from './types';

const EmptyMap = new Map();

export const FlagContext = createContext<FlagContextValue>({
  getDeterminations: () => {
    if (!import.meta.env.JEST_WORKER_ID && !import.meta.env.VITEST_POOL_ID) {
      console.warn('Flag check performed outside of a flag provider. This should only be done in a test environment.');
    }
    return EmptyMap;
  },
  addFeatureListener: () => {},
  removeFeatureListener: () => {},
  addGlobalListener: () => {},
  removeGlobalListener: () => {},
});
