import { ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import { NavigateFunction } from 'react-router-dom';

export const errorLink = (history: NavigateFunction) =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        const { message, locations, path } = error;
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

        if (import.meta.env.PROD) {
          Sentry.withScope(scope => {
            scope.setTag('type', 'graphql');
            scope.setTag('path', path as any);
            scope.setExtra('locations', locations);
            Sentry.captureException(error);
          });
        }
      });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);

      if ((networkError as ServerError).statusCode === 401) {
        history('./login');
      }
    }
  });
