import { Link as MuiLink, Typography } from '@mui/material';
import LogoDark from 'assets/brand/logo-dark.svg?react';
import Logo from 'assets/brand/logo.svg?react';
import ErrorIllustration from 'assets/illustrations/empty.svg?react';
import { FC, useEffect, useRef } from 'react';
import { useConfig } from 'shared/utils/ConfigProvider';
import { FallbackProps } from 'react-error-boundary';
import css from './ErrorComponent.module.css';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

export const ErrorComponent: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const { name } = useConfig();
  const isRefresh = error?.toString()?.indexOf('Chunk') > -1;
  const { key } = useLocation();
  const initialKey = useRef(key);

  /**
   * When the user attempts a navigation, we should reset the error boundary, so they aren't just
   * trapped on the error page. We can use the location key for this, because it changes every time
   * the router receives a navigation.
   */
  useEffect(() => {
    /**
     * But we should only trigger if the key has changed since the initial render, so we don't
     * just immediately reset the error boundary as soon as it displays.
     */
    if (initialKey.current !== key) {
      resetErrorBoundary();
    }
  }, [key]);

  useEffect(() => {
    if (isRefresh) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }, [isRefresh]);

  return isRefresh ? (
    <div className={css.container}>
      <Typography variant="h1">Updating</Typography>
    </div>
  ) : (
    <div className={css.error}>
      {name === 'dark' ? <LogoDark className={css.logo} /> : <Logo className={css.logo} />}
      <Typography variant="h1">Error</Typography>
      <Typography variant="h2">Something bad happened</Typography>
      <br />
      <Typography>Here are some helpful links instead:</Typography>
      <div className={css.quickLinks}>
        <MuiLink href="https://www.query.ai/">Query.ai</MuiLink>
        <MuiLink component={RouterLink} to="/search">
          Search Home
        </MuiLink>
        <MuiLink href="https://www.query.ai/resources/">Resources</MuiLink>
        <MuiLink href="https://www.query.ai/contact-us-support/">Contact</MuiLink>
      </div>
      <ErrorIllustration className={css.errorIllustration} />
    </div>
  );
};
