import { useCallback, useEffect, useRef } from 'react';
import {
  ClientContext,
  EdgeFeatureHubConfig,
  Readyness,
  FeatureValueType,
  ReadynessListener,
  FeatureHubPollingClient,
  PostLoadNewFeatureStateAvailableListener,
} from 'featurehub-javascript-client-sdk';
import * as Sentry from '@sentry/browser';
import { useAuth } from 'core/Auth';
import { Flag, FlagListener, FlagType, GlobalFlagListener } from './types';
import { FlagContext } from './FlagContext';
import isEqual from 'lodash/isEqual';

interface Props {
  children: JSX.Element;
}

export function FeatureHubFlagProvider({ children }: Props) {
  const determinations = useRef<ReadonlyMap<string, Flag>>(new Map());
  const featureListeners = useRef<Map<string, Set<FlagListener>>>(new Map());
  const globalListeners = useRef<Set<GlobalFlagListener>>(new Set());

  const { user, isLoading } = useAuth();

  const config_ = useRef<EdgeFeatureHubConfig | null>(null);
  const context_ = useRef<ClientContext | null>(null);
  if (config_.current === null) {
    config_.current = new EdgeFeatureHubConfig(
      import.meta.env.VITE_FEATUREHUB_URL,
      import.meta.env.VITE_FEATUREHUB_API_KEY
    );
    const pollInterval = Number(import.meta.env.VITE_FEATUREHUB_POLL_RATE);
    config_.current.edgeServiceProvider((repo, c) => new FeatureHubPollingClient(repo, c, pollInterval));
  }

  const notifyListeners = useCallback((oldFlags: ReadonlyMap<string, Flag>, newFlags: ReadonlyMap<string, Flag>) => {
    const removedFlagKeys = [...oldFlags.keys()].filter(key => !newFlags.has(key));
    const changedFlags = [...newFlags.entries()].filter(([key, flag]) => {
      return !isEqual(flag, oldFlags.get(key));
    });

    for (const listener of globalListeners.current) {
      listener(determinations.current);
    }

    for (const key of removedFlagKeys) {
      const listeners = featureListeners.current.get(key) ?? [];
      for (const listener of listeners) {
        listener(undefined);
      }
    }

    for (const [key, flag] of changedFlags) {
      const listeners = featureListeners.current.get(key) ?? [];
      for (const listener of listeners) {
        listener(flag);
      }
    }
  }, []);

  // This effect is responsible for setting up the listeners that will actually receive and process
  // updated flag determinations from FeatureHub.
  useEffect(() => {
    if (config_.current === null) {
      return;
    }
    const config = config_.current;
    // The first listener is the one that gets used when the client is first connected. According to
    // their docs, this should also get called on every subsequent update, but they are liars.
    const initialListener: ReadynessListener = readiness => {
      if (readiness !== Readyness.Ready) {
        if (readiness === Readyness.Failed) {
          Sentry.captureMessage('FeatureHub client failed to connect');
        }
        return;
      }
      const context = context_.current;
      const oldFlags = determinations.current;
      const newFlags = getFeatureDetails(context!);
      determinations.current = newFlags;
      notifyListeners(oldFlags, newFlags);
    };
    // The second listener will be the one that gets hit for subsequent updates (but only if a flag
    // actually changed between polling intervals).
    const updateListener: PostLoadNewFeatureStateAvailableListener = () => {
      const context = context_.current;
      const oldFlags = determinations.current;
      const newFlags = getFeatureDetails(context!);
      determinations.current = newFlags;
      notifyListeners(oldFlags, newFlags);
    };
    config.addReadinessListener(initialListener);
    config.repository().addPostLoadNewFeatureStateAvailableListener(updateListener);

    return () => {
      config.removeReadinessListener(initialListener);
      config.repository().removePostLoadNewFeatureStateAvailableListener(updateListener);
    };
  }, [notifyListeners]);

  useEffect(() => {
    if (isLoading) {
      // Auth is loading; don't start polling for flags until we figure out whether they are
      // authenticated and who they are.
      return;
    }
    let context;
    if (context_.current) {
      context = context_.current;
    } else {
      context = config_.current!.newContext();
      context_.current = context;
    }
    if (user) {
      context = context_.current
        .userKey(user.id)
        .attributeValue('userId', user.id)
        .attributeValue('tenantId', user.tenantId);
    }
    context.build().catch(error => {
      Sentry.captureException(error);
    });
  }, [isLoading, user]);

  const getDeterminations = useCallback(() => determinations.current, []);

  const addFeatureListener = useCallback((feature: string, listener: FlagListener) => {
    const listeners = featureListeners.current.get(feature) || new Set();
    listeners.add(listener);
    featureListeners.current.set(feature, listeners);
  }, []);

  const removeFeatureListener = useCallback((feature: string, listener: FlagListener) => {
    const listeners = featureListeners.current.get(feature);
    if (listeners) {
      listeners.delete(listener);
    }
  }, []);

  const addGlobalListener = useCallback((listener: GlobalFlagListener) => {
    globalListeners.current.add(listener);
  }, []);

  const removeGlobalListener = useCallback((listener: GlobalFlagListener) => {
    globalListeners.current.delete(listener);
  }, []);

  return (
    <FlagContext.Provider
      value={{
        getDeterminations,
        addFeatureListener,
        removeFeatureListener,
        addGlobalListener,
        removeGlobalListener,
      }}
    >
      {children}
    </FlagContext.Provider>
  );
}

function getFeatureDetails(context: ClientContext) {
  const featureKeys = context.repository().simpleFeatures().keys();
  const featureMap = new Map<string, Flag>();
  for (const key of featureKeys) {
    const { type } = context.feature(key);
    let flag: Flag;
    let jsonString: string | undefined;
    let jsonValue: unknown;
    switch (type) {
      case FeatureValueType.Boolean:
        flag = { key, type: FlagType.BOOLEAN, value: context.getBoolean(key) ?? false };
        break;
      case FeatureValueType.String:
        flag = { key, type: FlagType.STRING, value: context.getString(key) ?? '' };
        break;
      case FeatureValueType.Number:
        flag = { key, type: FlagType.NUMBER, value: context.getNumber(key) ?? 0 };
        break;
      case FeatureValueType.Json:
        jsonString = context.getRawJson(key);
        if (!jsonString) {
          jsonValue = undefined;
        } else {
          try {
            jsonValue = JSON.parse(jsonString);
          } catch (e) {
            console.error(`Error parsing JSON for feature flag ${key}:`, e);
            jsonValue = undefined;
          }
        }
        flag = { key, type: FlagType.JSON, value: jsonValue };
        break;
      default:
        console.warn(`Unknown feature type: ${type}`);
        flag = { key, type: FlagType.BOOLEAN, value: false };
    }
    featureMap.set(key, flag);
  }
  return featureMap;
}
