import { Button, ButtonProps, SvgIcon, SvgIconTypeMap } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { FunctionComponent } from 'react';

export type MuiButtonProps = Pick<
  ButtonProps,
  'children' | 'size' | 'disabled' | 'onClick' | 'variant' | 'sx' | 'color' | 'type'
> & {
  icon?:
    | OverridableComponent<SvgIconTypeMap<object, 'svg'>>
    | FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >;
  endIcon?:
    | OverridableComponent<SvgIconTypeMap<object, 'svg'>>
    | FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >;
};

// The button component is used for text buttons and icon + text buttons. For just icons, use the MuiIconButton component.
export const MuiButton = ({
  children,
  disabled,
  variant = 'contained',
  size,
  icon,
  onClick,
  sx,
  color,
  type,
  endIcon,
}: MuiButtonProps) => (
  <Button
    disabled={disabled}
    variant={variant}
    size={size}
    onClick={onClick}
    sx={sx}
    color={color}
    type={type}
    endIcon={endIcon && <SvgIcon component={endIcon} inheritViewBox fontSize="inherit" />}
    startIcon={icon && <SvgIcon component={icon} inheritViewBox fontSize="inherit" />}
  >
    {children}
  </Button>
);
